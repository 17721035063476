/** @jsx jsx */
import { jsx } from "theme-ui"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { ContactSection } from "../components/contact-section"
import { ContactForm } from "../components/contact-form"
import React from "react"
import { dataStorageKeys, getLocalStorageItem } from "../util/local-storage-wrapper"
import { ProductsSection } from "../components/products-section"
import { GatsbyImage } from "gatsby-plugin-image"
import { LiveChatButton } from "../components/live-chat-button"
import { ConfidenceBanner } from "../components/confidence-banner"

export const pageQuery = graphql`
  query AboutQuery($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        title
        warrantyPara
        servicePara
        assistancePara
        picture {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
    site {
      siteMetadata {
        title
      }
    }
  }
`

const About = ({ data }) => {
  const { markdownRemark, site } = data // data.markdownRemark holds your post data
  const {
    frontmatter: pageData,
    html
  } = markdownRemark

  const { warrantyPara, assistancePara, servicePara } = pageData

  console.log(warrantyPara, assistancePara, servicePara)

  const getImage = (image, alt) => image && image.childImageSharp && image.childImageSharp.gatsbyImageData
    ? <GatsbyImage alt={alt} image={image.childImageSharp.gatsbyImageData} />
    : ''

  const [quoteData, setQuoteData] = React.useState(null)

  React.useEffect(() => {
    if (!quoteData) {
      setQuoteData(getLocalStorageItem(dataStorageKeys.quoteData))
    }
  }, [quoteData, setQuoteData])

  return (
    <Layout className="contact-page">
      <Seo
        title={pageData.title}
        description={pageData.title + " " + site.siteMetadata.title}
      />
      <section className="confidence about-content">
        <div className="confidence__content">
          <h1>{pageData.title}</h1>
          <div dangerouslySetInnerHTML={{ __html: html }} />
          <div className="live">
            <LiveChatButton label="Open a chat" />
          </div>
        </div>
        <div className="confidence__image">
          {getImage(pageData.picture, 'About')}
        </div>
      </section>
      {/*<Breadcrumb title={pageData.title}/>*/}
      <ProductsSection quoteData={quoteData} altData={[warrantyPara, servicePara, assistancePara]} />
      <div className="about-confidence">
        <ConfidenceBanner />
      </div>
      <div className="contact">
        <div className="contact-content about-contact">
          <ContactForm />
          <ContactSection isContactPage={false} />
        </div>
      </div>
    </Layout>
  )
}

export default About
