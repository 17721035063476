/** @jsx jsx */
import { jsx } from "theme-ui"
import { graphql, useStaticQuery } from "gatsby"

const logoCircleUrl = '/assets/site/logo-TSBWC-circle.png'
const logoUrl = '/assets/site/logo-TSBWC.png'

const query = graphql`
  query ConfidenceQuery {
    confidenceBanner: file(name: { eq: "confidence-banner" }) {
      props: childMarkdownRemark {
        body: html
        bits: frontmatter {
          title
        }
      }
    }
  }
`

export const ConfidenceBanner = () => {
  const {
    confidenceBanner: {
      props: { body, bits: { title } }
    }
  } = useStaticQuery(query)

  return <div className="confidence-banner">
    <div className="confidence-banner-content">
      <h3>{title}</h3>
      <div className="confidence-copy" dangerouslySetInnerHTML={{ __html: body }}/>
    </div>
    <div className="confidence-banner-images">
      <img src={logoCircleUrl} alt="confidence" />
      <img src={logoUrl} alt="more confidence" />
    </div>
  </div>
}
