/** @jsx jsx */
import { jsx } from "theme-ui"
import { ProductTitle } from "./product-title"
import { graphql, Link, useStaticQuery } from "gatsby"
import { dataStorageKeys, setLocalStorageItem } from "../util/local-storage-wrapper"

const query = graphql`
  {
    warranty: file(name: { eq: "warranty-plan" }) {
      props: childMarkdownRemark {
        body: html
        bits: frontmatter {
          title
          price
        }
      }
    }
    service: file(name: { eq: "service-plan" }) {
      props: childMarkdownRemark {
        body: html
        bits: frontmatter {
          title
          price
        }
      }
    }
    assistance: file(name: { eq: "assistance-plan" }) {
      props: childMarkdownRemark {
        body: html
        bits: frontmatter {
          title
          price
        }
      }
    }
  }
`

const signupAction = (data) => {
  if (typeof window !== 'undefined') {
    setLocalStorageItem(dataStorageKeys.selectedProduct, data)

    window.location.href = '../signup'
  }
}

export const Product = ({ theme, productLabel, data, getQuote, para = '' }) => {
  const productName = productLabel.toLowerCase()
  const { [productName]: { props: { body, bits: { title, price: cmsPrice } } } } = useStaticQuery(query)

  const srcSet = [
    `/assets/site/${productName}-lg.png`,
    `/assets/site/${productName}-md.png`,
    `/assets/site/${productName}-sm.png`
  ]

  const { monthlyCost: quotePrice } = (data || {})

  const isDark = theme === 'dark'

  const titlePrice = quotePrice ? `£${quotePrice}` : `From £${cmsPrice}`

  const actionButton = data
    ? <button className="button button-electric-blue" onClick={() => signupAction(data)}>Buy now: £{quotePrice}</button>
    : <button className="button button-electric-blue" onClick={getQuote}>Get price</button>

  return <section className={`product${isDark ? '' : ' flex-reverse'}`}>
    <div className="product__image">
      <picture>
        <source media="(min-width: 1022px)" srcSet={srcSet[0]}/>
        <source media="(min-width: 576px)" srcSet={srcSet[1]}/>
        <img src={srcSet[2]} alt={productLabel}/>
      </picture>
    </div>
    <div className={`product__list${` ${theme}`}`}>
      <div id={`product-${productName}`} className="product__content">
        <ProductTitle title={title} icon={productName} price={titlePrice} iconColour={isDark ? 'light' : 'dark'}/>
        {para ? <p>{para}</p> : <div dangerouslySetInnerHTML={{ __html: body }}/>}
      </div>
      <div className={para ? "buttons para-section" : "buttons"}>
        <div>
          {getQuote ? actionButton : null}
        </div>
        <div>
          <Link to={`/${productName}`}>
            <button className="button learn">Learn more</button>
          </Link>
        </div>
      </div>
    </div>
  </section>
}
