/** @jsx jsx */
import { jsx } from "theme-ui"
import { individualProducts, packageProducts } from "../util/product-mappings"
import { Product } from "./product"

export const ProductsSection = ({ quoteData, initiateQuote, altData = [] }) =>
  packageProducts.map((product, i) => {
    const productData = quoteData && quoteData.find && quoteData.find(({ productCode }) => productCode === individualProducts[i])
    const theme = i % 2 === 0 ? 'dark' : 'light'

    return <Product
      key={`product-${i}`}
      productLabel={product}
      data={productData}
      theme={theme}
      getQuote={initiateQuote}
      para={altData[i]}/>
})
